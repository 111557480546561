import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import Layout from '../components/layout'

import MainBio from '../components/MainBio'
import Header from '../components/Header'
import Container from '../components/Container'
import Helmet from '../components/Helmet'
import Posts from '../components/Posts'
import Tags from '../components/Tags'

const TagsContainer = styled.p`
  font-size: 16px;
  font-style: italic;
  margin-bottom: 30px;
  color: rgba(0,0,0,.3);
  text-align: center;
`

const PostsContainer = styled(Container).attrs({
  size: 'wide',
})`
  > h2,
  > p {
    padding: 0 10px;
  }

  > h2 {
    margin-bottom: 0;
  }
`

class BlogIndex extends React.Component {
  render () {
    const posts = _.get(this, 'props.data.allMarkdownRemark.edges')
    const tags = _.get(this, 'props.data.allMarkdownRemark.group')
    const firstImage = _.get(posts, '0.node.frontmatter.image.childImageSharp.fluid')
    const favPosts = _.filter(posts, (post) => _.indexOf(_.get(post, 'node.frontmatter.tags'), 'favorites') > -1)
    const otherPosts = _.filter(posts, (post) => _.indexOf(_.get(post, 'node.frontmatter.tags'), 'favorites') < 0)

    return (
      <Layout>
        <Helmet
          image={firstImage}
          siteMetadata={this.props.data.site.siteMetadata} />
        <Header />

        <Container>
          <MainBio />
        </Container>

        <PostsContainer>
          <h2>Our Favorites</h2>
          <p>We make these drinks all the time.</p>
          <Posts posts={favPosts} />
        </PostsContainer>

        <PostsContainer>
          <h2 style={{ marginBottom: 30 }}>All Cocktails</h2>
          <Posts posts={otherPosts} />
          <TagsContainer>
            filter by <Tags tags={_.map(tags, 'fieldValue')} />
          </TagsContainer>
        </PostsContainer>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`

  query IndexQuery {
    site {
      ...HelmetFragment
    }
    allMarkdownRemark(sort: { fields: [frontmatter___title], order: ASC }) {
      edges {
        node {
          ...PostFragment
          ...PostThumbFragment
        }
      }
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
