import React from 'react'
import Footer from '../components/Footer'

class Template extends React.Component {
  render () {
    const { children } = this.props

    return (
      <div>
        {children}
        <Footer />
      </div>
    )
  }
}

export default Template
