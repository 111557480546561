import React from 'react'
import styled from 'styled-components'
import typography from '../utils/typography'
import media from '../utils/media'

const BioContainer = styled.div`
  margin: ${typography.rhythm(0.5)} 0 ${typography.rhythm(1.3)} 0;
  text-align: center;

  ${media.desktop`
    margin: ${typography.rhythm(1)} 0 ${typography.rhythm(2)} 0;
  `}
`

class MainBio extends React.Component {
  render () {
    return (
      <BioContainer>
        <p>
          We are Annie & Ben and we make cocktails in San Francisco. Below are
          our original creations. {' '}
          <a href="https://instagram.com/tippleandnosh">
            Follow us on Instagram
          </a>
        </p>
      </BioContainer>
    )
  }
}

export default MainBio
