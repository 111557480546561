import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import Container from './Container'

const FooterWrapper = styled.div`
  padding: 30px 0;
`

const NavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
`

const Brand = styled.div`
  font-size: 16px;
  margin: 0;
  line-height: 1;
`

const Nav = styled.nav`
  font-size: 16px;
  justify-self: flex-end;
`

const Footer = () => (
  <FooterWrapper>
    <Container>
      <NavWrapper>
        <Brand>
          <Link
            style={{
              boxShadow: 'none',
              textDecoration: 'none',
              color: 'inherit',
            }}
            to={'/'}>
            &copy; Tipple & Nosh {new Date().getFullYear()}
          </Link>
        </Brand>

        <Nav>
          <a href="https://instagram.com/tippleandnosh">
            About
          </a>
        </Nav>
      </NavWrapper>
    </Container>
  </FooterWrapper>
)

export default Footer
